import React, { useState, useContext } from 'react';

const initialAppState = {
  reverseHeader: false,
};

const AppContext = React.createContext({
  app: initialAppState,
  setApp: () => null,
});

const AppContextProvider = ({ children }) => {
  const [app, setApp] = useState(initialAppState);
  return (
    <AppContext.Provider
      value={{
        app,
        setApp,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

function useApp() {
  const { app } = useContext(AppContext);
  return app;
}

function useNav() {
  const {
    app: { reverseHeader },
    setApp,
  } = useContext(AppContext);

  async function setReverseHeader(value) {
    setApp((prevState) => {
      return { ...prevState, reverseHeader: value };
    });
  }

  return { reverseHeader, setReverseHeader };
}

export { useApp, useNav, AppContextProvider };
